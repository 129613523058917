

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function TearFaq() {
    return (
      <>
      <Separator />
      
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
        <Row className="justify-content-center pb-5">
          <Col lg="12">
   
            <Iwrap>

<Itext as="h2">Questions about <span>Tear Trough Under Eye</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       




        <Col
     
     >
        
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What is tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Tear trough treatment, also known as undereye filler, is a non-surgical procedure designed to reduce the appearance of dark circles, hollows, and lines under the eyes. This treatment uses dermal fillers to instantly rejuvenate and revive the under-eye area, making it appear brighter, firmer, and plumper, thereby reducing signs of tiredness and aging.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How long does the tear trough treatment take?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The tear trough treatment is a quick procedure that typically takes around 10 minutes to complete. This includes the time for numbing the area and administering the dermal filler injections.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What results can I expect from the tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    You can expect to see an immediate improvement in the appearance of your under-eye area. The treatment will reduce dark circles, hollows, and fine lines, making your eyes look more awake and youthful. The results are long-lasting, typically lasting between 12 to 18 months.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How much does the tear trough treatment cost?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The cost of the tear trough treatment is £450. This includes the initial consultation, the procedure itself, and any aftercare advice provided.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Is there any downtime after the tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    There is no significant downtime associated with tear trough treatment. You can return to your normal activities and work immediately after the procedure. However, you may experience mild swelling or bruising, which usually subsides within a few days.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Does the tear trough treatment hurt?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The tear trough treatment is generally well-tolerated with minimal discomfort. A numbing cream is applied to the area before the procedure to ensure your comfort. Most patients report feeling only mild discomfort, if any, during the injections.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Who is a good candidate for tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Ideal candidates for tear trough treatment are individuals who have dark circles, hollows, or fine lines under their eyes that make them look tired or older. This can be due to genetics, aging, or lifestyle factors. A consultation with our expert practitioner will determine if this treatment is suitable for you.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How should I prepare for my tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Before your tear trough treatment, you will have a private consultation with our expert practitioner to assess your facial anatomy and discuss your desired outcomes. You should avoid alcohol, aspirin, and other blood-thinning medications for at least 24 hours before the procedure to reduce the risk of bruising.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What should I expect during the tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    During the treatment, a numbing cream will be applied to the area to ensure your comfort. The practitioner will then use a fine needle to carefully inject the dermal filler into the targeted areas under your eyes. The entire process is quick and typically takes only a few minutes.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What aftercare is required following the tear trough treatment?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    After the treatment, you will receive comprehensive aftercare advice, which will also be emailed to you for convenience. It is recommended to avoid strenuous activities and excessive sun exposure for 24 hours. You should also refrain from touching or rubbing the treated area to prevent any complications. Any mild swelling or bruising should subside within a few days.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>


 


          </Col>




        



        </Row>



<br /> 
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment 
                
              
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

        <SeparatorEnd />
      </>


    );
}



